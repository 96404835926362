import {Form, Input, Modal} from 'antd';
import {t}                  from 'foundations/i18n/i18n';
import {useNavigate}        from 'react-router';
import {useApi}             from 'foundations/api/use-api';
import {useForm}            from 'foundations/form/use-form';
import {SelectCountry}      from 'features/availability-zones/select-country';
import {SelectVertical}     from 'features/verticals/select-vertical';

export function CreateAvailabilityZoneModal({
    open,
    toggle,
    providerId
}: {
    open: boolean,
    toggle: () => void,
    providerId: string,
}) {
    const form     = useForm({
        country:             'CAN',
        administrative_area: undefined,
        locality:            undefined,
        postal_code_fsa:     undefined,
        postal_code:         undefined,
        provider_id:         providerId,
        vertical_id:         undefined,
        value:               undefined
    });
    const navigate = useNavigate();
    const api      = useApi('post', '/availability-zones/create', form.data, {
        onSuccess: () => navigate(0)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Availability Zone')}
            onOk={api.submit}
            okButtonProps={{children: t('Create'), onClick: api.submit, loading: api.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Vertical')}>
                    <SelectVertical {...form.register('vertical_id')}/>
                </Form.Item>
                <Form.Item label={t('Country')}>
                    <SelectCountry {...form.register('country')}/>
                </Form.Item>
                <Form.Item label={t('Administrative Area')}>
                    <Input {...form.register('administrative_area')}/>
                </Form.Item>
                <Form.Item label={t('Locality')}>
                    <Input {...form.register('locality')}/>
                </Form.Item>
                <Form.Item label={t('Postal Code FSA')}>
                    <Input {...form.register('postal_code_fsa')}/>
                </Form.Item>
                <Form.Item label={t('Postal Code')}>
                    <Input {...form.register('postal_code')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}