import {DefaultLayout}          from 'foundations/layouts/default-layout';
import {t}                      from 'foundations/i18n/i18n';
import {Button, Divider, Space} from 'antd';
import {PlusOutlined}           from '@ant-design/icons';
import {CreateProviderModal}    from 'features/providers/create-provider-modal';
import {ProvidersTable}         from 'features/providers/providers-table';
import {useModal}               from 'foundations/ui/use-modal';

export function Providers() {

    const modal   = useModal();
    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} type='primary' onClick={modal.toggle}>{t('Provider')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={t('Providers')} actions={actions}>
            <CreateProviderModal open={modal.open} toggle={modal.toggle}/>
            <Divider/>
            <ProvidersTable/>
        </DefaultLayout>
    );
}