import {useEffect, useState} from 'react';
import {useApi}              from 'foundations/api/use-api';
import {Table}               from 'antd';
import {t}                   from 'foundations/i18n/i18n';
import {useModal}            from 'foundations/ui/use-modal';
import {UpdateCategoryModal} from 'features/categories/update-category-modal';

export function CategoryTable({
    query = {}
}: {
    query?: Record<string, any>
}) {
    const updateModal             = useModal();
    const [selected, setSelected] = useState<string>();
    const api                     = useApi('get', '/categories/list', {
        ...query,
        expand: ['vertical']
    });
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Label'),
            key:       'label',
            dataIndex: 'label'
        },
        {
            title:     t('Vertical'),
            key:       'vertical',
            dataIndex: ['vertical', 'name']
        },
        {
            title:     t('Order'),
            key:       'order',
            dataIndex: 'order',
            sorter:    (a: any, b: any) => a.order - b.order
        }
    ];

    const onRow = (record: any) => ({
        onClick: () => {
            setSelected(record.id);
            updateModal.toggle();
        }
    });

    return (
        <div>
            {
                selected &&
                <UpdateCategoryModal open={updateModal.open} toggle={updateModal.toggle} categoryId={selected}/>
            }
            <Table dataSource={api.res?.data} columns={cols} onRow={onRow}/>
        </div>
    );
}