import {DefaultLayout} from 'foundations/layouts/default-layout';
import {t}             from 'foundations/i18n/i18n';
import {Divider}       from 'antd';
import {OrderTable}    from 'features/orders/order-table';

export function Orders() {

    return (
        <DefaultLayout title={t('Orders')}>
            <Divider/>
            <OrderTable/>
        </DefaultLayout>
    );
}