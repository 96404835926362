import {t}                   from 'foundations/i18n/i18n';
import {Select, SelectProps} from 'antd';

export function SelectFieldLocation(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: t('Product'),
            value: 'product'
        }
    ];

    return <Select options={options} {...props}/>;
}