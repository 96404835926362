import {Form, Input, Modal} from 'antd';
import {t}                  from 'foundations/i18n/i18n';
import {useNavigate}        from 'react-router';
import {useApi}             from 'foundations/api/use-api';
import {useForm}            from 'foundations/form/use-form';
import {SelectVertical}     from 'features/verticals/select-vertical';

export function CreateCategoryModal({
    open,
    toggle,
    verticalId
}: {
    open: boolean,
    toggle: () => void,
    verticalId: string
}) {
    const form      = useForm({
        label:       undefined,
        vertical_id: verticalId
    });
    const navigate  = useNavigate();
    const createApi = useApi('post', '/categories/create', form.data, {
        onSuccess: (res) => navigate(`/categories/${res.data[0].id}`)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Category')}
            onOk={createApi.submit}
            okButtonProps={{children: t('Create'), onClick: createApi.submit, loading: createApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Label')}>
                    <Input {...form.register('label')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}