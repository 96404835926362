import {Button, Card, Form, Input, Typography} from 'antd';
import {t}                                     from 'foundations/i18n/i18n';
import {useApi}                                from 'foundations/api/use-api';
import {useForm}                               from 'foundations/form/use-form';
import {AuthLayout}                            from 'features/auth/auth-layout';
import {AuthService}                           from 'features/auth/auth-service';

export function Login() {
    const form = useForm();
    const api  = useApi('post', '/sessions/create', form.data, {
        onSuccess: (res) => {
            AuthService.signIn(res.data[0].token);
        }
    });

    return (
        <AuthLayout>
            <div style={{marginBottom: 24}}>
                <Typography.Title level={3}>{t('Sign in')}</Typography.Title>
                <Typography.Paragraph>{t('Sign in to your Boom account')}</Typography.Paragraph>
            </div>
            <Form layout='vertical'>
                <Form.Item label={t('Email')}>
                    <Input {...form.register('email')}/>
                </Form.Item>
                <Form.Item label={t('Password')}>
                    <Input.Password {...form.register('password')}/>
                </Form.Item>
            </Form>
            <Button block type={'primary'} onClick={api.submit} loading={api.status.working}>{'Sign In'}</Button>
        </AuthLayout>
    );
}