import {useEffect, useState}         from 'react';
import {useApi}                      from 'foundations/api/use-api';
import {Table}                       from 'antd';
import {t}                           from 'foundations/i18n/i18n';
import {useModal}                    from 'foundations/ui/use-modal';
import {UpdateProductAttributeModal} from 'features/product-attributes/update-product-attribute-modal';

export function ProductAttributesTable({
    query = {}
}: {
    query?: Record<string, any>
}) {
    const updateModal           = useModal();
    const [selected, setSelected] = useState();
    const api                   = useApi('get', '/product-attributes/list', {
        ...query,
        expand: ['product']
    });
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Label'),
            key:       'label',
            dataIndex: 'label'
        },
        {
            title:     t('Product'),
            key:       'product',
            dataIndex: ['product', 'name']
        }
    ];

    const onRow = (record: any) => ({
        onClick: () => {
            setSelected(record.id);
            updateModal.toggle();
        }
    });

    return (
        <div>
            {
                selected &&
                <UpdateProductAttributeModal open={updateModal.open} toggle={updateModal.toggle} attributeId={selected}/>
            }
            <Table dataSource={api.res?.data} columns={cols} onRow={onRow}/>
        </div>
    );
}