import {Col, Input, Row} from 'antd';
import React             from 'react';

export function ProductAttributesInput({
    attributes,
    value,
    name,
    onChange
}: {
    attributes: {
        id: string
        label: string,
    }[],
    name: string,
    value: Record<string, string> | null,
    onChange: (event: React.ChangeEvent) => void,
}) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            currentTarget: {
                name:  name,
                value: {
                    ...value,
                    [event.currentTarget.name]: event.currentTarget.value
                }
            }
        } as unknown as React.ChangeEvent);
    };

    return (
        <div>
            {
                attributes.map((attr) =>
                    <Row gutter={8} align={'middle'}>
                        <Col flex={'100px'}>
                            <span>{attr.label}</span>
                        </Col>
                        <Col flex={'auto'}>
                            <Input name={attr.id} onChange={handleChange} value={value?.[attr.id]}/>
                        </Col>
                    </Row>
                )
            }
        </div>
    );
}