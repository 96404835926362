import {Button, Form, Input, Typography} from 'antd';
import {t}                               from 'foundations/i18n/i18n';
import {useNavigate}                     from 'react-router';
import {useApi}                          from 'foundations/api/use-api';
import {useForm}                         from 'foundations/form/use-form';
import {AuthLayout}                      from 'features/auth/auth-layout';

export function CreatePasswordResetScreen() {
    const form     = useForm();
    const navigate = useNavigate();
    const api      = useApi('post', '/password-resets/create', form.data, {
        onSuccess: () => navigate('/password-reset/sent')
    });

    return (
        <AuthLayout>
            <div style={{marginBottom: 24}}>
                <Typography.Title level={3}>{t('Reset Password')}</Typography.Title>
                <Typography.Paragraph>{t('Enter your email to start resetting your password')}</Typography.Paragraph>
            </div>
            <Form layout='vertical'>
                <Form.Item label={t('Email')}>
                    <Input {...form.register('email')}/>
                </Form.Item>
            </Form>
            <Button block type={'primary'} onClick={api.submit} loading={api.status.working}>{'Continue'}</Button>
        </AuthLayout>
    );
}