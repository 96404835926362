import {Button, Form, Input} from 'antd';
import {t}                   from 'foundations/i18n/i18n';
import {useNavigate}         from 'react-router';
import {useApi}              from 'foundations/api/use-api';
import {useForm}             from 'foundations/form/use-form';
import {AuthLayout}          from 'features/auth/auth-layout';
import {useSearchParams}     from 'react-router-dom';

export function ConsumePasswordResetScreen() {
    const [searchParams] = useSearchParams();
    const form     = useForm({
        token:    searchParams.get('token'),
        password: undefined
    });
    const navigate = useNavigate();
    const api      = useApi('post', '/password-resets/consume', form.data, {
        onSuccess: () => navigate('/password-reset/complete')
    });

    return (
        <AuthLayout>
            <Form layout='vertical'>
                <Form.Item label={t('New Password')}>
                    <Input.Password {...form.register('password')}/>
                </Form.Item>
            </Form>
            <Button block type={'primary'} onClick={api.submit} loading={api.status.working}>{'Save'}</Button>
        </AuthLayout>
    );
}