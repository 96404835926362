import styles   from 'features/auth/auth-layout.module.css';
import React    from 'react';
import LogoDark from 'foundations/logos/logo-dark.png';

export function AuthLayout({
    children
}: {
    children: React.ReactNode
}) {
    return (
        <>
            <div className={styles.bg}/>
            <div className={styles.container}>
                <img src={LogoDark} className={styles.logo}/>
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </>
    );
}