import {useEffect}   from 'react';
import {useApi}      from 'foundations/api/use-api';
import {Table}       from 'antd';
import {t}           from 'foundations/i18n/i18n';
import {useNavigate} from 'react-router';

export function ProductTable({
    query = {}
}: {
    query?: Record<string, any>
}) {

    const navigate = useNavigate();
    const api      = useApi('get', '/products/list', {
        ...query,
        expand: ['provider', 'vertical']
    });
    useEffect(api.submit, []);

    const cols  = [
        {
            title:     t('Name'),
            key:       'name',
            dataIndex: 'name'
        },
        {
            title:     t('Provider'),
            key:       'provider',
            dataIndex: ['provider', 'name']
        },
        {
            title:     t('Vertical'),
            key:       'vertical',
            dataIndex: ['vertical', 'name']
        }
    ];
    const onRow = (record: any) => ({
        onClick: () => navigate(`/products/${record.id}`)
    });


    return (
        <div>
            <Table dataSource={api.res?.data} columns={cols} onRow={onRow}/>
        </div>
    );
}