import {Button, Divider, Space} from 'antd';
import {DefaultLayout}          from 'foundations/layouts/default-layout';
import {t}                      from 'foundations/i18n/i18n';
import {useModal}               from 'foundations/ui/use-modal';
import {PlusOutlined}           from '@ant-design/icons';
import {CreateAccountModal} from 'features/accounts/create-account-modal';
import {AccountTable}       from 'features/accounts/account-table';

export function AccountsScreen() {
    const modal   = useModal();
    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} type='primary' onClick={modal.toggle}>{t('Account')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={t('Accounts')} actions={actions}>
            <CreateAccountModal open={modal.open} toggle={modal.toggle}/>
            <Divider/>
            <AccountTable/>
        </DefaultLayout>
    );
}