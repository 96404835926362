import {Form, Input, InputNumber, Modal} from 'antd';
import {t}                               from 'foundations/i18n/i18n';
import {useApi}                          from 'foundations/api/use-api';
import {useForm}                         from 'foundations/form/use-form';
import {useEffect}                       from 'react';

export function UpdateCategoryModal({
    open,
    toggle,
    categoryId
}: {
    open: boolean,
    toggle: () => void,
    categoryId: string
}) {
    const form    = useForm();
    const loadApi = useApi('get', '/categories/list', {
        id: categoryId
    }, {
        onSuccess: (res) => {
            form.setData(res.data[0]);
        }
    });

    useEffect(loadApi.submit, []);

    const updateApi = useApi('patch', '/categories/update', form.data);

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Update Category')}
            onOk={updateApi.submit}
            okButtonProps={{children: t('Update'), onClick: updateApi.submit}}>
            <Form layout='vertical'>
                <Form.Item label={t('Label')}>
                    <Input {...form.register('label')}/>
                </Form.Item>
                <Form.Item label={t('Order')}>
                    {/*@ts-ignore*/}
                    <InputNumber {...form.register('order')} min={0}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}