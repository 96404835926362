import {Select, SelectProps} from 'antd';
import {useApi}              from 'foundations/api/use-api';
import {useEffect}           from 'react';

export function SelectVertical(props: Exclude<SelectProps, 'options'>) {

    const api     = useApi('get', '/verticals/list');
    const options = api.res?.data.map((vertical) => ({
        label: vertical.name,
        value: vertical.id
    }));

    useEffect(() => api.submit(), []);

    return <Select options={options} {...props} loading={api.status.working}/>;
}