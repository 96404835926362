import {useEffect}   from 'react';
import {useApi}      from 'foundations/api/use-api';
import {Table}       from 'antd';
import {t}           from 'foundations/i18n/i18n';
import {useNavigate} from 'react-router';

export function AccountTable({
    query = {}
}: {
    query?: Record<string, any>
}) {

    const navigate = useNavigate();
    const api      = useApi('get', '/accounts/list', query);
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Name'),
            key:       'name',
            dataIndex: 'name'
        },
        {
            title:     t('Email'),
            key:       'email',
            dataIndex: 'email'
        }
    ];

    const onRow = (record: any) => ({
        onClick: () => navigate(`/accounts/${record.id}`)
    });

    return (
        <div>
            <Table dataSource={api.res?.data} columns={cols} onRow={onRow}/>
        </div>
    );
}