import {DefaultLayout}          from 'foundations/layouts/default-layout';
import {t}                      from 'foundations/i18n/i18n';
import {Divider}                from 'antd';
import React                    from 'react';
import {ProductAttributesTable} from 'features/product-attributes/product-attributes-table';

export function ProductAttributesScreen() {
    return (
        <DefaultLayout title={t('Product Attributes')}>
            <Divider/>
            <ProductAttributesTable/>
        </DefaultLayout>
    );
}