import {useEffect, useState}         from 'react';
import {useApi}                      from 'foundations/api/use-api';
import {Table}                       from 'antd';
import {t}                           from 'foundations/i18n/i18n';
import {useModal}                    from 'foundations/ui/use-modal';
import {UpdateProductVariationModal} from 'features/product-variations/update-product-variation-modal';

export function ProductVariationTable({
    query = {}
}: {
    query?: Record<string, any>
}) {
    const updateModal             = useModal();
    const [selected, setSelected] = useState<string>();

    const api = useApi('get', '/products/list', query);
    useEffect(api.submit, []);

    // Antd defaultSortOrder typings are incorrect, use 'any'
    const cols: any = [
        {
            title:            t('Name'),
            key:              'name',
            dataIndex:        'name',
            sorter:           (a: any, b: any) => a.name > b.name ? 1 : -1,
            defaultSortOrder: 'ascend'
        },
        {
            title:     t('Attribute Values'),
            key:       'attribute_values',
            dataIndex: ['attributes', 0]
        }
    ];
    const onRow     = (record: any) => ({
        onClick: () => {
            setSelected(record.id);
            updateModal.toggle();
        }
    });

    return (
        <div>
            {
                selected &&
                <UpdateProductVariationModal open={updateModal.open} toggle={updateModal.toggle} productId={selected}/>
            }
            <Table dataSource={api.res?.data} columns={cols} onRow={onRow}/>
        </div>
    );
}