import {Form, Input, Modal} from 'antd';
import {t}                  from 'foundations/i18n/i18n';
import {useNavigate}        from 'react-router';
import {useApi}             from 'foundations/api/use-api';
import {useForm}            from 'foundations/form/use-form';
import {SelectPriceProcess} from 'features/products/select-price-process';
import {SelectVertical}     from 'features/verticals/select-vertical';
import {SelectTaxCode}      from 'features/products/select-tax-code';
import {SelectCategory}     from 'features/categories/select-category';
import {SwitchPublished}    from 'features/products/switch-published';

export function CreateProductModal({
    open,
    toggle,
    providerId
}: {
    open: boolean,
    toggle: () => void,
    providerId: string
}) {
    const form      = useForm({
        name:           undefined,
        description:    undefined,
        gtu:            undefined,
        price_amount:   0,
        price_currency: 'CAD',
        price_process:  'capture',
        tax_code:       'non_taxable',
        parent_id:      undefined,
        provider_id:    providerId,
        vertical_id:    undefined,
        category_id:    undefined,
        published:      true
    });
    const navigate  = useNavigate();
    const createApi = useApi('post', '/products/create', form.data, {
        onSuccess: (res) => navigate(`/products/${res.data[0].id}`)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Product')}
            onOk={createApi.submit}
            okButtonProps={{children: t('Create'), onClick: createApi.submit, loading: createApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Name')}>
                    <Input {...form.register('name')}/>
                </Form.Item>
                <Form.Item label={t('Published')}>
                    <SwitchPublished {...form.register('published')}/>
                </Form.Item>
                <Form.Item label={t('GTU')}>
                    <Input {...form.register('gtu')}/>
                </Form.Item>
                <Form.Item label={t('Vertical')}>
                    <SelectVertical {...form.register('vertical_id')}/>
                </Form.Item>
                <Form.Item label={t('Category')}>
                    <SelectCategory {...form.register('category_id')} query={{vertical_id: form.data.vertical_id}}/>
                </Form.Item>
                <Form.Item label={t('Price Amount')}>
                    <Input {...form.register('price_amount')}/>
                </Form.Item>
                <Form.Item label={t('Price Process')}>
                    <SelectPriceProcess {...form.register('price_process')}/>
                </Form.Item>
                <Form.Item label={t('Tax Code')}>
                    <SelectTaxCode {...form.register('tax_code')}/>
                </Form.Item>
                <Form.Item label={t('Description')}>
                    <Input.TextArea {...form.register('description')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}