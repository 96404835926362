import React  from 'react';
import {Boom} from '@boomhealth/sdk-js';

type Response = {
    status: {
        success: boolean,
        httpCode: number
    },
    data: Record<string, any>[],
    error?: {
        message: string,
        params?: Record<string, string[]>[];
    }
}

export const apiHost = () => {
    if (window.location.href.startsWith('http://localhost:3002')) {
        return 'http://localhost:3000';
    } else if (window.location.href.startsWith('https://ae.')) {
        return 'https://ae.api.boom.health';
    } else {
        return 'https://ca.api.boom.health';
    }
};

export function useApi(
    method: 'get' | 'post' | 'patch' | 'delete',
    endpoint: string,
    params?: Record<string, any>,
    options?: {
        onSuccess?: (res: Response) => void,
        requestType?: 'json' | 'multipart';
    }
) {
    const [res, setRes]       = React.useState<Response>();
    const [status, setStatus] = React.useState<'idle' | 'working'>('idle');

    const submit = React.useCallback(() => {
        setStatus('working');
        Boom.host           = apiHost();
        const requestMethod = options?.requestType === 'multipart' ? Boom.requestFile : Boom.request;
        const httpMethod    = method === 'patch' ? 'PATCH' : method;

        requestMethod(httpMethod as any, endpoint, params)
            .then((res: any) => {
                setRes(res);
                if (options?.onSuccess) {
                    options.onSuccess(res);
                }
            })
            .catch((res: Response) => setRes(res))
            .finally(() => setStatus('idle'));
    }, [method, endpoint, params, options]);

    return {
        res:    res,
        submit: submit,
        status: {
            idle:    status === 'idle',
            working: status === 'working'
        }
    };
}