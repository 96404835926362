import Cookies      from 'js-cookie';
import {Boom}       from '@boomhealth/sdk-js';
import * as process from 'process';
import {apiHost}    from 'foundations/api/use-api';

export type AuthPackage = {
    session: Record<any, any>,
    account: Record<any, any>,
}

export class AuthService {
    public static async init(): Promise<AuthPackage> {
        const token = AuthService.getSessionToken();
        Boom.host   = apiHost();

        if (typeof token === 'undefined') {
            // Pretend that we returned full auth, because no un-authed component will use this result
            Boom.key = process.env.REACT_APP_API_KEY as string;
            return {} as AuthPackage;
        } else {
            Boom.key = token;

            try {
                // Populate initial store data
                const session = await Boom.request('get', '/sessions/list', {
                    token: token
                });

                const account = await Boom.request('get', '/accounts/list', {
                    id: session.data[0].account_id
                });

                return {
                    session: session.data[0],
                    account: account.data[0]
                };
            } catch (e) {
                return {} as AuthPackage;
            }
        }
    }

    public static get isSignedIn(): boolean {
        return typeof Cookies.get('session') !== 'undefined';
    }

    public static signIn(token: string) {
        Cookies.set('session', token);
        window.location.href = '/';
    }

    public static getSessionToken() {
        return Cookies.get('session');
    }

    public static signOut() {
        Cookies.remove('session');
        window.location.href = '/login';
    }
}