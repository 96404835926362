import {Form, Input, Modal, Switch}       from 'antd';
import {t}                                from 'foundations/i18n/i18n';
import {useNavigate}                      from 'react-router';
import {useForm}                          from 'foundations/form/use-form';
import {useApi}                           from 'foundations/api/use-api';
import {SelectVerticalDisplayMode}        from 'features/verticals/select-vertical-display-mode';
import {SelectVerticalPreviewDisplayMode} from 'features/verticals/select-vertical-preview-display-mode';

export function CreateVerticalModal({
    open,
    toggle
}: {
    open: boolean,
    toggle: () => void
}) {
    const form = useForm({
        name:                 undefined,
        long_name:            undefined,
        tagline:              undefined,
        enable_cart:          false,
        display_mode:         'compact',
        preview_display_mode: 'tile',
        description:          undefined
    });

    const navigate = useNavigate();
    const api      = useApi('post', '/verticals/create', form.data, {
        onSuccess: (res) => navigate(`/verticals/${res.data[0].id}`)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Vertical')}
            okButtonProps={{children: t('Create'), onClick: api.submit, loading: api.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Display Mode')}>
                    <SelectVerticalDisplayMode  {...form.register('display_mode')} onChange={value => form.set('display_mode', value)}/>
                </Form.Item>
                <Form.Item label={t('Preview Display Mode')}>
                    <SelectVerticalPreviewDisplayMode  {...form.register('preview_display_mode')} onChange={value => form.set('preview_display_mode', value)}/>
                </Form.Item>
                <Form.Item label={t('Name')}>
                    <Input {...form.register('name')}/>
                </Form.Item>
                <Form.Item label={t('Long Name')}>
                    <Input {...form.register('long_name')}/>
                </Form.Item>
                <Form.Item label={t('Tagline')}>
                    <Input {...form.register('tagline')}/>
                </Form.Item>
                <Form.Item label={t('Enable Cart')}>
                    <Switch {...form.register('enable_cart')}/>
                </Form.Item>
                <Form.Item label={t('Description')}>
                    <Input.TextArea {...form.register('description')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}