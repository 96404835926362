import {useEffect} from 'react';
import {useApi}    from 'foundations/api/use-api';
import {Table}     from 'antd';
import {t}         from 'foundations/i18n/i18n';

export function PermissionsTable({
    query = {}
}: {
    query?: Record<string, any>
}) {

    const api = useApi('get', '/permissions/list', query);
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Account ID'),
            key:       'account_id',
            dataIndex: 'account_id'
        },
        {
            title:     t('Platform'),
            key:       'platform',
            dataIndex: 'platform'
        },
        {
            title:     t('Role'),
            key:       'role',
            dataIndex: 'role'
        }
    ];

    return (
        <div>
            <Table dataSource={api.res?.data} columns={cols}/>
        </div>
    );
}