import {DefaultLayout}          from 'foundations/layouts/default-layout';
import {t}                      from 'foundations/i18n/i18n';
import {Button, Divider, Space} from 'antd';
import {PlusOutlined}           from '@ant-design/icons';
import {useModal}               from 'foundations/ui/use-modal';
import React                    from 'react';
import {CreateBannerModal}      from 'features/banners/create-banner-modal';
import {BannersTable}           from 'features/banners/banners-table';

export function BannersScreen() {
    const modal = useModal();

    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} type='primary' onClick={modal.toggle}>{t('Banner')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={t('Banners')} actions={actions}>
            <CreateBannerModal open={modal.open} toggle={modal.toggle}/>
            <Divider/>
            <BannersTable/>
        </DefaultLayout>
    );
}