import {Form, Modal}         from 'antd';
import {t}                   from 'foundations/i18n/i18n';
import {useNavigate}         from 'react-router';
import {useApi}              from 'foundations/api/use-api';
import {useForm}             from 'foundations/form/use-form';
import {useEffect, useState} from 'react';

export function CreateBannerModal({
    open,
    toggle
}: {
    open: boolean,
    toggle: () => void,
}) {
    const [image, setImage] = useState<File>();
    const form              = useForm();
    const navigate          = useNavigate();

    const createApi = useApi('post', '/banners/create', form.data, {
        onSuccess: () => navigate(0)
    });

    const uploadApi = useApi('post', '/files/create', {
        file:    image,
        purpose: 'banner_image'
    }, {
        onSuccess:   (res) => {
            form.set('image_id', res.data[0].id);
        },
        requestType: 'multipart'
    });

    useEffect(() => {
        if (image) {
            uploadApi.submit();
        }
    }, [image]);

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Banner')}
            onOk={createApi.submit}
            okButtonProps={{children: t('Create'), onClick: createApi.submit, loading: createApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Image')}>
                    <input type='file' onChange={(event) => setImage(event?.target?.files?.[0])}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}