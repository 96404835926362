import {Form, Input, Modal, Switch} from 'antd';
import {t}                          from 'foundations/i18n/i18n';
import {useNavigate}                from 'react-router';
import {useApi}                     from 'foundations/api/use-api';
import {useForm}                    from 'foundations/form/use-form';
import {useEffect, useState}        from 'react';
import {SelectVerticalDisplayMode}  from 'features/verticals/select-vertical-display-mode';

export function UpdateVerticalModal({
    open,
    toggle,
    verticalId
}: {
    open: boolean,
    toggle: () => void,
    verticalId: string
}) {
    const [image, setImage] = useState<File>();
    const form              = useForm();
    const navigate          = useNavigate();
    const loadApi           = useApi('get', '/verticals/list', {
        id: verticalId
    }, {
        onSuccess: (res) => {
            form.setData(res.data[0]);
        }
    });

    useEffect(loadApi.submit, []);

    const updateApi = useApi('patch', '/verticals/update', form.data, {
        onSuccess: (res) => navigate(`/products/${res.data[0].id}`)
    });

    const uploadApi = useApi('post', '/files/create', {
        file:    image,
        purpose: 'vertical_image'
    }, {
        onSuccess:   (res) => {
            form.set('image_id', res.data[0].id);
        },
        requestType: 'multipart'
    });

    useEffect(() => {
        if (image) {
            uploadApi.submit();
        }
    }, [image]);

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Update Vertical')}
            onOk={updateApi.submit}
            okButtonProps={{children: t('Update'), onClick: updateApi.submit, loading: updateApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Image')}>
                    <input type='file' onChange={(event) => setImage(event?.target?.files?.[0])}/>
                </Form.Item>
                <Form.Item label={t('Display Mode')}>
                    <SelectVerticalDisplayMode  {...form.register('display_mode')} onChange={value => form.set('display_mode', value)}/>
                </Form.Item>
                <Form.Item label={t('Name')}>
                    <Input {...form.register('name')}/>
                </Form.Item>
                <Form.Item label={t('Enable Cart')}>
                    <Switch {...form.register('enable_cart')}/>
                </Form.Item>
                <Form.Item label={t('Description')}>
                    <Input.TextArea {...form.register('description')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}