import {Divider}       from 'antd';
import React           from 'react';
import {DefaultLayout} from 'foundations/layouts/default-layout';
import {t}             from 'foundations/i18n/i18n';
import {FieldsTable}   from 'features/fields/fields-table';

export function FieldsScreen() {
    return (
        <DefaultLayout title={t('Fields')}>
            <Divider/>
            <FieldsTable/>
        </DefaultLayout>
    );
}