import {Form, Input, Modal}     from 'antd';
import {t}                      from 'foundations/i18n/i18n';
import {useApi}                 from 'foundations/api/use-api';
import {useForm}                from 'foundations/form/use-form';
import {SelectPriceProcess}     from 'features/products/select-price-process';
import {SelectTaxCode}          from 'features/products/select-tax-code';
import {useEffect}              from 'react';
import {ProductAttributesInput} from 'features/products/product-attributes-input';

export function UpdateProductVariationModal({
    open,
    toggle,
    productId
}: {
    open: boolean,
    toggle: () => void,
    productId: string
}) {
    const form    = useForm();
    const loadApi = useApi('get', '/products/list', {
        id:     productId,
        expand: ['parent.attributes']
    }, {
        onSuccess: (res) => {
            form.setData(res.data[0]);
        }
    });

    useEffect(loadApi.submit, [productId]);

    const updateApi = useApi('patch', '/products/update', form.data);

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Update Product')}
            onOk={updateApi.submit}
            okButtonProps={{children: t('Update'), onClick: updateApi.submit, loading: updateApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Name')}>
                    <Input {...form.register('name')}/>
                </Form.Item>
                <Form.Item label={t('GTU')}>
                    <Input {...form.register('gtu')}/>
                </Form.Item>
                <Form.Item label={t('Price Amount')}>
                    <Input {...form.register('price_amount')}/>
                </Form.Item>
                <Form.Item label={t('Price Process')}>
                    <SelectPriceProcess {...form.register('price_process')}/>
                </Form.Item>
                <Form.Item label={t('Tax Code')}>
                    <SelectTaxCode {...form.register('tax_code')}/>
                </Form.Item>
                <Form.Item label={t('Description')}>
                    <Input.TextArea {...form.register('description')}/>
                </Form.Item>
                <Form.Item label={t('Attribute Values')}>
                    <ProductAttributesInput {...form.register('attribute_values')} attributes={loadApi.res?.data[0]?.parent?.attributes ?? []}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}