import {DefaultLayout}               from 'foundations/layouts/default-layout';
import {t}                           from 'foundations/i18n/i18n';
import {Button, Space, Tabs}         from 'antd';
import {useApi}                      from 'foundations/api/use-api';
import {useParams}                   from 'react-router';
import React, {useEffect}            from 'react';
import {FieldsTable}                 from 'features/fields/fields-table';
import {ProductAttributesTable}      from 'features/product-attributes/product-attributes-table';
import {useModal}                    from 'foundations/ui/use-modal';
import {CreateProductAttributeModal} from 'features/product-attributes/create-product-attribute-modal';
import {EditOutlined, PlusOutlined}  from '@ant-design/icons';
import {ProductVariationTable}       from 'features/product-variations/product-variation-table';
import {UpdateProductModal}          from 'features/products/update-product-modal';
import {CreateProductVariationModal} from 'features/product-variations/create-product-variation-modal';
import {CreateFieldModal}            from 'features/fields/create-field-modal';
import {CreateProductImageModal}     from 'features/product-image/create-product-image-modal';
import {ProductImageTable}           from 'features/product-image/product-image-table';

export function ProductScreen() {
    const {id}                 = useParams();
    const createFieldModal     = useModal();
    const createAttributeModal = useModal();
    const createVariantModal   = useModal();
    const createImageModal     = useModal();
    const updateProductModal   = useModal();

    const api = useApi('get', `/products/list`, {id});
    useEffect(api.submit, []);

    const tabs = [
        {
            key:      'images',
            label:    t('Images'),
            children: <ProductImageTable query={{product_id: id}}/>
        },
        {
            key:      'attributes',
            label:    t('Attributes'),
            children: <ProductAttributesTable query={{product_id: id}}/>
        },
        {
            key:      'fields',
            label:    t('Fields'),
            children: <FieldsTable query={{product_id: id}}/>
        },
        {
            key:      'variations',
            label:    t('Variations'),
            children: <ProductVariationTable query={{parent_id: id}}/>
        }
    ];

    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} onClick={createImageModal.toggle}>{t('Image')}</Button>
            <Button icon={<PlusOutlined/>} onClick={createFieldModal.toggle}>{t('Field')}</Button>
            <Button icon={<PlusOutlined/>} onClick={createAttributeModal.toggle}>{t('Attribute')}</Button>
            <Button icon={<PlusOutlined/>} onClick={createVariantModal.toggle}>{t('Variation')}</Button>
            <Button icon={<EditOutlined/>} onClick={updateProductModal.toggle} type={'primary'}>{t('Update')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={api.res?.data[0]?.name} actions={actions}>
            <CreateProductImageModal open={createImageModal.open} toggle={createImageModal.toggle} productId={id!}/>
            <CreateFieldModal open={createFieldModal.open} toggle={createFieldModal.toggle} productId={id!}/>
            <UpdateProductModal open={updateProductModal.open} toggle={updateProductModal.toggle} productId={id!}/>
            <CreateProductAttributeModal open={createAttributeModal.open} toggle={createAttributeModal.toggle} productId={id!}/>
            {
                api.res?.data[0].vertical_id &&
                <CreateProductVariationModal open={createVariantModal.open} toggle={createVariantModal.toggle} providerId={api.res.data[0].provider_id} parentId={id!} verticalId={api.res?.data[0].vertical_id}/>

            }
            <Tabs items={tabs}/>
        </DefaultLayout>
    );
}