import {useEffect} from 'react';
import {useApi}    from 'foundations/api/use-api';
import {Table}     from 'antd';
import {t}         from 'foundations/i18n/i18n';

export function AvailabilityZoneTable({
    query = {}
}: {
    query?: Record<string, any>
}) {
    const api = useApi('get', '/availability-zones/list', {
        expand: ['provider', 'vertical'],
        ...query
    });
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Vertical'),
            key:       'vertical',
            dataIndex: ['vertical', 'name']
        },
        {
            title:     t('Country'),
            key:       'country',
            dataIndex: 'country'
        },
        {
            title:     t('Administrative Area'),
            key:       'administrative_area',
            dataIndex: 'administrative_area'
        },
        {
            title:     t('Locality'),
            key:       'locality',
            dataIndex: 'locality'
        },
        {
            title:     t('Postal Code FSA'),
            key:       'postal_code_fsa',
            dataIndex: 'postal_code_fsa'
        }
    ];

    return (
        <div>
            <Table dataSource={api.res?.data} columns={cols}/>
        </div>
    );
}