import {useEffect} from 'react';
import {useApi}    from 'foundations/api/use-api';
import {Table}     from 'antd';
import {t}         from 'foundations/i18n/i18n';

export function ContactMethodsTable({
    query = {}
}: {
    query?: Record<string, any>
}) {
    const api = useApi('get', '/contact-methods/list', query);
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Type'),
            key:       'type',
            dataIndex: 'type'
        },
        {
            title:     t('Value'),
            key:       'value',
            dataIndex: 'value'
        }
    ];

    return (
        <div>
            <Table dataSource={api.res?.data} columns={cols}/>
        </div>
    );
}