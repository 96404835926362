import {useEffect} from 'react';
import {useApi}    from 'foundations/api/use-api';
import {Table}     from 'antd';
import {t}         from 'foundations/i18n/i18n';

export function FieldsTable({
    query = {}
}: {
    query?: Record<string, any>
}) {

    const api = useApi('get', '/fields/list', {
        ...query,
        expand: ['product']
    });
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Name'),
            key:       'name',
            dataIndex: 'name'
        },
        {
            title:     t('Label'),
            key:       'label',
            dataIndex: 'label'
        },
        {
            title:     t('Product'),
            key:       'product',
            dataIndex: ['product', 'name']
        }
    ];

    return (
        <div>
            <Table dataSource={api.res?.data} columns={cols}/>
        </div>
    );
}