import {Typography} from 'antd';
import {t}          from 'foundations/i18n/i18n';
import {AuthLayout} from 'features/auth/auth-layout';

export function SentPasswordResetScreen() {
    return (
        <AuthLayout>
            <div style={{marginBottom: 24}}>
                <Typography.Title level={3}>{t('Reset Email Sent')}</Typography.Title>
                <Typography.Paragraph>{t('We\'ve sent you an email with further instructions. Please check your inbox.')}</Typography.Paragraph>
            </div>
        </AuthLayout>
    );
}