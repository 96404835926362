import {useEffect}   from 'react';
import {useApi}      from 'foundations/api/use-api';
import {Table}       from 'antd';
import {t}           from 'foundations/i18n/i18n';

export function BannersTable({
    query = {}
}: {
    query?: Record<string, any>
}) {
    const api      = useApi('get', '/banners/list', query);
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Image ID'),
            key:       'image_id',
            dataIndex: 'image_id'
        },
    ];

    return (
        <div>
            <Table dataSource={api.res?.data} columns={cols}/>
        </div>
    );
}