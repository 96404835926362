import {Form, Modal}         from 'antd';
import {t}                   from 'foundations/i18n/i18n';
import {useApi}              from 'foundations/api/use-api';
import {useForm}             from 'foundations/form/use-form';
import {useEffect, useState} from 'react';

export function CreateProductImageModal({
    open,
    toggle,
    productId
}: {
    open: boolean,
    toggle: () => void,
    productId: string
}) {
    const [image, setImage] = useState<File>();
    const form              = useForm({
        product_id: productId,
        file_id:    undefined
    });

    const updateApi = useApi('post', '/product-images/create', form.data);
    const uploadApi = useApi('post', '/files/create', {
        file:    image,
        purpose: 'product_image'
    }, {
        onSuccess:   (res) => {
            form.set('file_id', res.data[0].id);
        },
        requestType: 'multipart'
    });

    useEffect(() => {
        if (form.data.file_id) {
            updateApi.submit();
        }
    }, [form.data.file_id]);

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Product Image')}
            onOk={uploadApi.submit}
            okButtonProps={{children: t('Create'), onClick: uploadApi.submit, loading: updateApi.status.working || uploadApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Name')}>
                    <input type='file' onChange={(event) => setImage(event?.target?.files?.[0])}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}