import {Form, Input, Modal}  from 'antd';
import {t}                   from 'foundations/i18n/i18n';
import {useNavigate}         from 'react-router';
import {useApi}              from 'foundations/api/use-api';
import {useForm}             from 'foundations/form/use-form';
import {SelectFieldFormat}   from 'features/fields/select-field-format';
import {SelectFieldLocation} from 'features/fields/select-field-location';

export function CreateFieldModal({
    open,
    toggle,
    productId
}: {
    open: boolean,
    toggle: () => void,
    productId: string
}) {
    const form     = useForm({
        format:     'text',
        name:       undefined,
        label:      undefined,
        options:    undefined,
        product_id: productId,
        location:   'product'
    });
    const navigate = useNavigate();
    const api      = useApi('post', '/fields/create', form.data, {
        onSuccess: () => navigate(0)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Field')}
            onOk={api.submit}
            okButtonProps={{children: t('Create'), onClick: api.submit, loading: api.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Location')}>
                    <SelectFieldLocation {...form.register('location')} disabled/>
                </Form.Item>
                <Form.Item label={t('Name')}>
                    <Input {...form.register('name')}/>
                </Form.Item>
                <Form.Item label={t('Label')}>
                    <Input {...form.register('label')}/>
                </Form.Item>
                <Form.Item label={t('Format')}>
                    <SelectFieldFormat {...form.register('format')}/>
                </Form.Item>
                {
                    form.data.format === 'select' &&
                    <Form.Item label={t('Options')}>
                        <Input {...form.register('options')}/>
                    </Form.Item>
                }
            </Form>
        </Modal>
    );
}