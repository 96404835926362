import {Form, Input, Modal} from 'antd';
import {t}                  from 'foundations/i18n/i18n';
import {useNavigate}        from 'react-router';
import {useForm}            from 'foundations/form/use-form';
import {useApi}             from 'foundations/api/use-api';

export function CreateCouponModal({
    open,
    toggle
}: {
    open: boolean,
    toggle: () => void
}) {
    const form = useForm({
        name:        undefined,
        amount_off:  undefined,
        percent_off: undefined
    });

    const navigate = useNavigate();
    const api      = useApi('post', '/coupons/create', form.data, {
        onSuccess: () => navigate(0)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Coupon')}
            okButtonProps={{children: t('Create'), onClick: api.submit, loading: api.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Name')}>
                    <Input {...form.register('name')}/>
                </Form.Item>
                <Form.Item label={t('Amount Off')}>
                    <Input {...form.register('amount_off')}/>
                </Form.Item>
                <Form.Item label={t('Percent Off')}>
                    <Input {...form.register('percent_off')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}