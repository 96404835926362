import React from 'react';

export function useForm<S extends Record<string, any>>(data: Partial<S> = {}) {
    const [formData, setFormData] = React.useState<Partial<S>>(data);

    const register = (name: keyof S) => {
        return {
            name:     name,
            value:    formData[name] as any,
            onChange: (event: React.ChangeEvent<any> | string | boolean | number) => {
                set(name, typeof event === 'object' ? event.currentTarget.value : event);
            }
        };
    };

    const set = (name: keyof S, value: any) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return {
        data:     formData as S,
        set:      set,
        setData:  setFormData,
        register: register
    };
}