import {Form, Modal}               from 'antd';
import {t}                         from 'foundations/i18n/i18n';
import {useNavigate}               from 'react-router';
import {useApi}                    from 'foundations/api/use-api';
import {useForm}                   from 'foundations/form/use-form';
import {SelectPermissionsPlatform} from 'features/permissions/select-permissions-platform';
import {SelectPermissionsRole}     from 'features/permissions/select-permissions-role';

export function CreatePermissionsModal({
    open,
    toggle,
    accountId
}: {
    open: boolean,
    toggle: () => void,
    accountId: string
}) {
    const form     = useForm({
        platform:   'backhouse',
        role:       'admin',
        account_id: accountId
    });
    const navigate = useNavigate();
    const api      = useApi('post', '/permissions/create', form.data, {
        onSuccess: () => navigate(0)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Permission')}
            onOk={api.submit}
            okButtonProps={{children: t('Create'), onClick: api.submit, loading: api.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Product')}>
                    <SelectPermissionsPlatform {...form.register('platform')}/>
                </Form.Item>
                <Form.Item label={t('Role')}>
                    <SelectPermissionsRole {...form.register('role')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}