import {Divider}               from 'antd';
import {t}                     from 'foundations/i18n/i18n';
import React                   from 'react';
import {DefaultLayout}         from 'foundations/layouts/default-layout';
import {AvailabilityZoneTable} from 'features/availability-zones/availability-zone-table';

export function AvailabilityZones() {
    return (
        <DefaultLayout title={t('Availability Zones')}>
            <Divider/>
            <AvailabilityZoneTable/>
        </DefaultLayout>
    );
}