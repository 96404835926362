import {DefaultLayout}              from 'foundations/layouts/default-layout';
import {t}                          from 'foundations/i18n/i18n';
import {Button, Space, Tabs}        from 'antd';
import {useApi}                     from 'foundations/api/use-api';
import {useParams}                  from 'react-router';
import React, {useEffect}           from 'react';
import {useModal}                   from 'foundations/ui/use-modal';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {CategoryTable}              from 'features/categories/category-table';
import {CreateCategoryModal}        from 'features/categories/create-category-modal';
import {UpdateVerticalModal}        from 'features/verticals/update-vertical-modal';

export function VerticalScreen() {
    const {id}                = useParams();
    const createCategoryModal = useModal();
    const updateVerticalModal = useModal();

    const api = useApi('get', `/verticals/list`, {id});
    useEffect(api.submit, []);

    const tabs = [
        {
            key:      'categories',
            label:    t('Categories'),
            children: <CategoryTable query={{vertical_id: id}}/>
        }
    ];

    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} onClick={createCategoryModal.toggle}>{t('Category')}</Button>
            <Button icon={<EditOutlined/>} onClick={updateVerticalModal.toggle} type={'primary'}>{t('Update')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={api.res?.data[0]?.name} actions={actions}>
            <CreateCategoryModal open={createCategoryModal.open} toggle={createCategoryModal.toggle} verticalId={id!}/>
            <UpdateVerticalModal open={updateVerticalModal.open} toggle={createCategoryModal.toggle} verticalId={id!}/>
            <Tabs items={tabs}/>
        </DefaultLayout>
    );
}