import {Select, SelectProps} from 'antd';
import {useApi}              from 'foundations/api/use-api';
import {useEffect}           from 'react';

export function SelectCategory(props: Exclude<SelectProps, 'options'> & { query: Record<string, any> }) {
    const api     = useApi('get', '/categories/list', props.query);
    const options = api.res?.data.map((category) => ({
        label: category.label,
        value: category.id
    }));

    useEffect(() => api.submit(), []);

    return <Select options={options} {...props} loading={api.status.working}/>;
}