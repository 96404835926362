import {Select, SelectProps} from 'antd';

export function SelectPermissionsPlatform(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: 'Backhouse',
            value: 'backhouse'
        },
        {
            label: 'Vendor',
            value: 'vendor '
        }
    ];

    return <Select options={options} {...props} disabled/>;
}