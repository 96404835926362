import {DefaultLayout}          from 'foundations/layouts/default-layout';
import {t}                      from 'foundations/i18n/i18n';
import {Button, Space, Tabs}    from 'antd';
import {useApi}                 from 'foundations/api/use-api';
import {useParams}              from 'react-router';
import React, {useEffect}       from 'react';
import {useModal}               from 'foundations/ui/use-modal';
import {PlusOutlined}           from '@ant-design/icons';
import {PermissionsTable}       from 'features/permissions/permissions-table';
import {CreatePermissionsModal} from 'features/permissions/create-permissions-modal';

export function AccountScreen() {
    const {id}                   = useParams();
    const createPermissionsModal = useModal();

    const api = useApi('get', `/accounts/list`, {id});
    useEffect(api.submit, []);

    const tabs = [
        {
            key:      'permissions',
            label:    t('Permissions'),
            children: <PermissionsTable query={{account_id: id}}/>
        }
    ];

    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} onClick={createPermissionsModal.toggle}>{t('Permission')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={api.res?.data[0]?.name} actions={actions}>
            <CreatePermissionsModal open={createPermissionsModal.open} toggle={createPermissionsModal.toggle} accountId={id!}/>
            <Tabs items={tabs}/>
        </DefaultLayout>
    );
}