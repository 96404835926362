"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Boom = void 0;
class Boom {
    static set host(host) {
        this._host = host;
    }
    /**
     * Add a listener that will be notified when a request fails
     *
     * @param listener
     */
    static onError(listener) {
        this.errorListeners.push(listener);
    }
    static notifyListeners(error) {
        this.errorListeners.forEach((listener) => listener(error));
    }
}
exports.Boom = Boom;
_a = Boom;
Boom.Hosts = {
    DEV: 'http://localhost:3002',
    CA: 'https://ca.api.boom.health'
};
/**
 * Send requests to production APIs or local development APIs
 */
Boom._host = Boom.Hosts.DEV;
Boom.errorListeners = [];
/**
 * Make a request against our next generation API
 *
 * @param method
 * @param endpoint
 * @param params
 */
Boom.request = (method, endpoint, params) => __awaiter(void 0, void 0, void 0, function* () {
    const options = {
        method: method,
        headers: {
            'X-Api-Key': Boom.key,
            'Content-Type': 'application/json'
        },
        mode: 'cors'
    };
    let response = {
        status: {
            success: false,
            httpCode: 500
        },
        data: [],
        error: {
            type: 'boom_network_error',
            message: 'Request was not received by server',
            params: {}
        }
    };
    // Build params, either as part of the body or query string, depending on the request method
    if (params !== undefined && Object.keys(params).length > 0) {
        if (method === 'get') {
            // Delete undefined
            Object.keys(params).forEach(key => params[key] === undefined ? delete params[key] : {});
            endpoint += '?' + new URLSearchParams(params);
        }
        else {
            options.body = JSON.stringify(params);
        }
    }
    try {
        const root = _a._host;
        const rawResponse = yield fetch(`${root}${endpoint}`, options);
        response = yield rawResponse.json();
    }
    catch (error) {
        response.error = {
            type: 'boom_network_error',
            message: 'A network error occurred',
            params: {}
        };
    }
    if (response.status.success) {
        return response;
    }
    else {
        _a.notifyListeners(response);
        throw response;
    }
});
Boom.requestFile = (method, endpoint, params) => __awaiter(void 0, void 0, void 0, function* () {
    const options = {
        method: method,
        headers: {
            'X-Api-Key': Boom.key
        },
        mode: 'cors'
    };
    let response = {
        status: {
            success: false,
            httpCode: 500
        },
        data: [],
        error: {
            type: 'boom_network_error',
            message: 'Request was not received by server',
            params: {}
        }
    };
    // Build params, either as part of the body or query string, depending on the request method
    if (params !== undefined && Object.keys(params).length > 0) {
        const body = new FormData();
        for (const [name, value] of Object.entries(params)) {
            body.append(name, value);
        }
        options.body = body;
    }
    try {
        const root = _a._host;
        const rawResponse = yield fetch(`${root}${endpoint}`, options);
        response = yield rawResponse.json();
    }
    catch (error) {
        response.error = {
            type: 'boom_network_error',
            message: 'A network error occurred',
            params: {}
        };
    }
    if (response.status.success) {
        return response;
    }
    else {
        _a.notifyListeners(response);
        throw response;
    }
});
