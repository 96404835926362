import {DefaultLayout}          from 'foundations/layouts/default-layout';
import {t}                      from 'foundations/i18n/i18n';
import {Button, Divider, Space} from 'antd';
import {PlusOutlined}           from '@ant-design/icons';
import {useModal}               from 'foundations/ui/use-modal';
import React                    from 'react';
import {CreateCouponModal}      from 'features/coupons/create-coupon-modal';
import {CouponsTable}           from 'features/coupons/coupons-table';

export function CouponsScreen() {
    const modal = useModal();

    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} type='primary' onClick={modal.toggle}>{t('Coupons')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={t('Coupons')} actions={actions}>
            <CreateCouponModal open={modal.open} toggle={modal.toggle}/>
            <Divider/>
            <CouponsTable/>
        </DefaultLayout>
    );
}