import {DefaultLayout} from 'foundations/layouts/default-layout';
import {t}             from 'foundations/i18n/i18n';
import {Divider}       from 'antd';
import React           from 'react';
import {ProductTable}  from 'features/products/product-table';

export function ProductsScreen() {
    return (
        <DefaultLayout title={t('Products')}>
            <Divider/>
            <ProductTable query={{parent_id: null}}/>
        </DefaultLayout>
    );
}