import {useEffect}   from 'react';
import {useApi}      from 'foundations/api/use-api';
import {Table}       from 'antd';
import {t}           from 'foundations/i18n/i18n';
import {useNavigate} from 'react-router';

export function ProvidersTable({
    query = {}
}: {
    query?: Record<string, any>
}) {
    const navigate = useNavigate();
    const api      = useApi('get', '/providers/list', query);
    useEffect(api.submit, []);

    const cols = [
        {
            title:     t('Name'),
            key:       'name',
            dataIndex: 'name'
        }
    ];

    const onRow = (record: any) => ({
        onClick: () => navigate(`/providers/${record.id}`)
    });

    return (
        <div>
            <Table onRow={onRow} dataSource={api.res?.data} columns={cols}/>
        </div>
    );
}