import {DefaultLayout}               from 'foundations/layouts/default-layout';
import {t}                           from 'foundations/i18n/i18n';
import {Button, Space, Tabs}         from 'antd';
import {useApi}                      from 'foundations/api/use-api';
import {useParams}                   from 'react-router';
import React, {useEffect}            from 'react';
import {AvailabilityZoneTable}       from 'features/availability-zones/availability-zone-table';
import {ProductTable}                from 'features/products/product-table';
import {CreateProductModal}          from 'features/products/create-product-modal';
import {useModal}                    from 'foundations/ui/use-modal';
import {PlusOutlined}                from '@ant-design/icons';
import {CreateAvailabilityZoneModal} from 'features/availability-zones/create-availability-zone-modal';
import {ContactMethodsTable}         from 'features/contact-methods/contact-methods-table';
import {CreateContactMethodModal}    from 'features/contact-methods/create-contact-method-modal';

export function Provider() {
    const {id}               = useParams();
    const api                = useApi('get', `/providers/list`, {id});
    const createProductModal = useModal();
    const createZoneModal    = useModal();
    const createContactModal = useModal();

    useEffect(api.submit, []);

    const tabs = [
        {
            key:      'products',
            label:    t('Products'),
            children: <ProductTable query={{provider_id: id, parent_id: null}}/>
        },
        {
            key:      'zones',
            label:    t('Zones'),
            children: <AvailabilityZoneTable query={{provider_id: id}}/>
        },
        {
            key:      'contact_methods',
            label:    t('Contact Methods'),
            children: <ContactMethodsTable query={{provider_id: id}}/>
        }
    ];

    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} onClick={createProductModal.toggle}>{t('Product')}</Button>
            <Button icon={<PlusOutlined/>} onClick={createZoneModal.toggle}>{t('Zone')}</Button>
            <Button icon={<PlusOutlined/>} onClick={createContactModal.toggle}>{t('Contact')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={api.res?.data[0]?.name} actions={actions}>
            <CreateProductModal open={createProductModal.open} toggle={createProductModal.toggle} providerId={id!}/>
            <CreateAvailabilityZoneModal open={createZoneModal.open} toggle={createZoneModal.toggle} providerId={id!}/>
            <CreateContactMethodModal open={createContactModal.open} toggle={createContactModal.toggle} providerId={id!}/>
            <Tabs items={tabs}/>
        </DefaultLayout>
    );
}