import {DefaultLayout} from 'foundations/layouts/default-layout';
import {t}             from 'foundations/i18n/i18n';
import React           from 'react';
import {CategoryTable} from 'features/categories/category-table';

export function CategoriesScreen() {
    return (
        <DefaultLayout title={t('Categories')}>
            <CategoryTable/>
        </DefaultLayout>
    );
}