import {Select, SelectProps} from 'antd';

export function SelectTaxCode(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: 'General Goods',
            value: 'general_goods'
        },
        {
            label: 'General Services',
            value: 'general_services'
        },
        {
            label: 'Non-Taxable',
            value: 'non_taxable'
        }
    ];

    return <Select options={options} {...props}/>;
}