import {Form, Input, InputNumber, Modal} from 'antd';
import {t}                               from 'foundations/i18n/i18n';
import {useNavigate}                     from 'react-router';
import {useApi}                          from 'foundations/api/use-api';
import {useForm}                         from 'foundations/form/use-form';

export function CreateProductAttributeModal({
    open,
    toggle,
    productId
}: {
    open: boolean,
    toggle: () => void,
    productId: string,
}) {
    const form      = useForm({
        label:      undefined,
        order:      0,
        product_id: productId
    });
    const navigate  = useNavigate();
    const createApi = useApi('post', '/product-attributes/create', form.data, {
        onSuccess: (res) => navigate(0)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Product Attribute')}
            onOk={createApi.submit}
            okButtonProps={{children: t('Create'), onClick: createApi.submit, loading: createApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Label')}>
                    <Input {...form.register('label')}/>
                </Form.Item>
                <Form.Item label={t('Order')}>
                    {/*@ts-ignore*/}
                    <InputNumber {...form.register('order')} min={0}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}