import {Divider}          from 'antd';
import React              from 'react';
import {DefaultLayout}    from 'foundations/layouts/default-layout';
import {t}                from 'foundations/i18n/i18n';
import {PermissionsTable} from 'features/permissions/permissions-table';

export function PermissionsScreen() {
    return (
        <DefaultLayout title={t('Permissions')}>
            <Divider/>
            <PermissionsTable/>
        </DefaultLayout>
    );
}