import React, {useEffect, useState}   from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {AccountsScreen}               from 'features/accounts/accounts-screen';
import {Providers}                    from 'features/providers/providers';
import {Home}                         from 'features/home/home';
import {AvailabilityZones}            from 'features/availability-zones/availability-zones';
import {Verticals}                    from 'features/verticals/verticals';
import {ProductsScreen}               from 'features/products/products-screen';
import {Orders}                       from 'features/orders/orders';
import {FieldsScreen}                 from 'features/fields/fields-screen';
import {AuthPackage, AuthService}     from 'features/auth/auth-service';
import {AuthContext}                  from 'features/auth/auth-context';
import {Login}                        from 'features/auth/login/login';
import {Signup}                       from 'features/auth/signup/signup';
import {PublicRoutes}                 from 'foundations/routing/public-routes';
import {PrivateRoutes}                from 'foundations/routing/private-routes';
import {Provider}                     from 'features/providers/provider';
import {Spin}                         from 'antd';
import {ProductAttributesScreen}      from 'features/product-attributes/product-attributes-screen';
import {ProductScreen}                from 'features/products/product-screen';
import {CategoriesScreen}             from 'features/categories/categories-screen';
import {VerticalScreen}               from 'features/verticals/vertical-screen';
import {PermissionsScreen}            from 'features/permissions/permissions-screen';
import {AccountScreen}                from 'features/accounts/account-screen';
import {ConsumePasswordResetScreen}   from 'features/auth/password-reset/consume-password-reset-screen';
import {CreatePasswordResetScreen}    from 'features/auth/password-reset/create-password-reset-screen';
import {CompletePasswordResetScreen}  from 'features/auth/password-reset/complete-password-reset-screen';
import {SentPasswordResetScreen}      from 'features/auth/password-reset/sent-password-reset-screen';
import {BannersScreen}                from 'features/banners/banners-screen';
import {CouponsScreen}                from 'features/coupons/coupons-screen';

export function App() {
    const [loading, setLoading] = useState(true);
    const [auth, setAuth]       = useState<AuthPackage>({} as AuthPackage);

    useEffect(() => {
        AuthService.init()
            .then(setAuth)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <Spin size={'large'} tip={'Loading'} style={{
            position:  'fixed',
            top:       '50%',
            left:      '50%',
            transform: 'translate(-50%, -50%)'
        }}/>;
    }

    return (
        <AuthContext.Provider value={auth}>
            <BrowserRouter>
                <Routes>
                    <Route path='/password-reset' element={<CreatePasswordResetScreen/>}/>
                    <Route path='/password-reset/consume' element={<ConsumePasswordResetScreen/>}/>
                    <Route path='/password-reset/complete' element={<CompletePasswordResetScreen/>}/>
                    <Route path='/password-reset/sent' element={<SentPasswordResetScreen/>}/>
                    <Route element={<PublicRoutes/>}>
                        <Route path='/login' element={<Login/>}/>
                        <Route path='/signup' element={<Signup/>}/>

                    </Route>
                    <Route element={<PrivateRoutes/>}>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/accounts' element={<AccountsScreen/>}/>
                        <Route path='/accounts/:id' element={<AccountScreen/>}/>
                        <Route path='/banners' element={<BannersScreen/>}/>
                        <Route path='/categories' element={<CategoriesScreen/>}/>
                        <Route path='/coupons' element={<CouponsScreen/>}/>
                        <Route path='/providers' element={<Providers/>}/>
                        <Route path='/providers/:id' element={<Provider/>}/>
                        <Route path='/permissions' element={<PermissionsScreen/>}/>
                        <Route path='/availability-zones' element={<AvailabilityZones/>}/>
                        <Route path='/products' element={<ProductsScreen/>}/>
                        <Route path='/products/:id' element={<ProductScreen/>}/>
                        <Route path='/product-attributes' element={<ProductAttributesScreen/>}/>
                        <Route path='/orders' element={<Orders/>}/>
                        <Route path='/fields' element={<FieldsScreen/>}/>
                        <Route path='/verticals' element={<Verticals/>}/>
                        <Route path='/verticals/:id' element={<VerticalScreen/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthContext.Provider>
    );
}