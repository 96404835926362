import {Select, SelectProps} from 'antd';

export function SelectPermissionsRole(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: 'Admin',
            value: 'admin'
        }
    ];

    return <Select options={options} {...props} disabled/>;
}