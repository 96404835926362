import {Form, Input, Modal}     from 'antd';
import {t}                      from 'foundations/i18n/i18n';
import {useNavigate}            from 'react-router';
import {useApi}                 from 'foundations/api/use-api';
import {useForm}                from 'foundations/form/use-form';
import {SelectPriceProcess}     from 'features/products/select-price-process';
import {SelectTaxCode}          from 'features/products/select-tax-code';
import {ProductAttributesInput} from 'features/products/product-attributes-input';
import {useEffect}              from 'react';

export function CreateProductVariationModal({
    open,
    toggle,
    providerId,
    parentId,
    verticalId
}: {
    open: boolean,
    toggle: () => void,
    providerId: string,
    parentId: string,
    verticalId: string,
}) {
    const form      = useForm({
        name:             undefined,
        description:      undefined,
        gtu:              undefined,
        price_amount:     0,
        price_currency:   'CAD',
        price_process:    'capture',
        tax_code:         'non_taxable',
        attribute_values: undefined,
        parent_id:        parentId,
        provider_id:      providerId,
        vertical_id:      verticalId
    });
    const navigate  = useNavigate();
    const loadApi   = useApi('get', '/products/list', {
        id:     parentId,
        expand: ['attributes']
    });
    const createApi = useApi('post', '/products/create', form.data, {
        onSuccess: (res) => navigate(`/products/${res.data[0].id}`)
    });

    useEffect(loadApi.submit, []);
    
    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Product')}
            onOk={createApi.submit}
            okButtonProps={{children: t('Create'), onClick: createApi.submit, loading: createApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Name')}>
                    <Input {...form.register('name')}/>
                </Form.Item>
                <Form.Item label={t('GTU')}>
                    <Input {...form.register('gtu')}/>
                </Form.Item>
                <Form.Item label={t('Price Amount')}>
                    <Input {...form.register('price_amount')}/>
                </Form.Item>
                <Form.Item label={t('Price Process')}>
                    <SelectPriceProcess {...form.register('price_process')}/>
                </Form.Item>
                <Form.Item label={t('Tax Code')}>
                    <SelectTaxCode {...form.register('tax_code')}/>
                </Form.Item>
                <Form.Item label={t('Attribute Values')}>
                    <ProductAttributesInput {...form.register('attribute_values')} attributes={loadApi.res?.data[0]?.attributes ?? []}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}