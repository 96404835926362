import {Select, SelectProps} from 'antd';

export function SelectPriceProcess(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: 'Hold',
            value: 'hold'
        },
        {
            label: 'Capture',
            value: 'capture'
        },
    ];

    return <Select options={options} {...props}/>;
}