import {t}                   from 'foundations/i18n/i18n';
import {Select, SelectProps} from 'antd';

export function SelectVerticalDisplayMode(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: t('Compact'),
            value: 'compact'
        },
        {
            label: t('Emphasized'),
            value: 'emphasized'
        }
    ];

    return <Select options={options} {...props}/>;
}