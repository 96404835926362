import {Switch} from 'antd';
import React    from 'react';

export function SwitchPublished({
    value,
    name,
    onChange
}: {
    value: boolean,
    name: string,
    onChange: (value: boolean) => void
}) {
    return <Switch checked={value} onChange={onChange}/>;
}