import {Form, Input, Modal} from 'antd';
import {t}                  from 'foundations/i18n/i18n';
import {useApi}             from 'foundations/api/use-api';
import {useForm}            from 'foundations/form/use-form';

export function CreateContactMethodModal({
    open,
    toggle,
    providerId
}: {
    open: boolean,
    toggle: () => void,
    providerId: string
}) {
    const form      = useForm({
        type:        'email',
        value:       undefined,
        provider_id: providerId
    });
    const createApi = useApi('post', '/contact-methods/create', form.data);

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Contact Method')}
            onOk={createApi.submit}
            okButtonProps={{children: t('Create'), onClick: createApi.submit, loading: createApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Email')}>
                    <Input {...form.register('value')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}