import {DefaultLayout}          from 'foundations/layouts/default-layout';
import {t}                      from 'foundations/i18n/i18n';
import {Button, Divider, Space} from 'antd';
import {PlusOutlined}           from '@ant-design/icons';
import {useModal}               from 'foundations/ui/use-modal';
import React                    from 'react';
import {CreateVerticalModal}    from 'features/verticals/create-vertical-modal';
import {VerticalsTable}         from 'features/verticals/verticals-table';

export function Verticals() {
    const modal = useModal();

    const actions = (
        <Space>
            <Button icon={<PlusOutlined/>} type='primary' onClick={modal.toggle}>{t('Vertical')}</Button>
        </Space>
    );

    return (
        <DefaultLayout title={t('Verticals')} actions={actions}>
            <CreateVerticalModal open={modal.open} toggle={modal.toggle}/>
            <Divider/>
            <VerticalsTable/>
        </DefaultLayout>
    );
}