import {Typography} from 'antd';
import {t}          from 'foundations/i18n/i18n';
import {AuthLayout} from 'features/auth/auth-layout';

export function CompletePasswordResetScreen() {
    return (
        <AuthLayout>
            <div style={{marginBottom: 24}}>
                <Typography.Title level={3}>{t('Successfully Reset')}</Typography.Title>
                <Typography.Paragraph>{t('Please close this window and return to the app')}</Typography.Paragraph>
            </div>
        </AuthLayout>
    );
}