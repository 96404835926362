import {t}                   from 'foundations/i18n/i18n';
import {Select, SelectProps} from 'antd';

export function SelectCountry(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: t('Canada'),
            value: 'CAN'
        },
        {
            label: t('United States of America'),
            value: 'USA'
        },
        {
            label: t('United Arab Emirates'),
            value: 'ARE'
        }
    ];

    return <Select options={options} {...props}/>;
}