import {t}                   from 'foundations/i18n/i18n';
import {Select, SelectProps} from 'antd';

export function SelectFieldFormat(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: t('Text'),
            value: 'text'
        },
        {
            label: t('Long Text'),
            value: 'long_text'
        },
        {
            label: t('Select'),
            value: 'select'
        },
        {
            label: t('Date'),
            value: 'date'
        },
        {
            label: t('Time'),
            value: 'time'
        },
        {
            label: t('Datetime'),
            value: 'datetime'
        },
        {
            label: t('Address'),
            value: 'address'
        }
    ];

    return <Select options={options} {...props}/>;
}