import {t}                   from 'foundations/i18n/i18n';
import {Select, SelectProps} from 'antd';

export function SelectVerticalPreviewDisplayMode(props: Exclude<SelectProps, 'options'>) {
    const options = [
        {
            label: t('Tile'),
            value: 'tile'
        },
        {
            label: t('Product List'),
            value: 'product_list'
        }
    ];

    return <Select options={options} {...props}/>;
}